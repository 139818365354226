import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { isLoggedIn } from "../api/Authentication";
import userStore from "../contexts/UserStore";

export const ProtectedRoutes = () => {
    const [isLogged, setIsLogged] = useState(false);
    const navigate = useNavigate();

    const setUser = userStore((state) => state.setUser);
    const clearUser = userStore((state) => state.clearUser);

    // Fonction pour vérifier si l'utilisateur est connecté
    const isUserLoggedIn = async () => {
        try {
            const data = await isLoggedIn();
            if (data) {
                setIsLogged(true);
                setUser(data.user);
            }
        } catch (error) {
            console.error("Error fetching isLoggedIn:", error);
            clearUser();
            setIsLogged(false);
            return navigate("/auth/connexion", { replace: true });
        }
    };

    useEffect(() => {
        isUserLoggedIn();
    }, [isLogged]);

    return isLogged ? <Outlet /> : null;
};
