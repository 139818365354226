import { axiosInstance } from "../utils/axiosConfig";

// Fonction pour se connecter
export const login = async (email, password) => {
    try {
        const response = await axiosInstance.post("/auth/login", {
            email,
            password,
        });
        return response.data;
    } catch (error) {
        console.error("Error fetching login:", error);
        // Vérifie si l'API a renvoyé une réponse d'erreur
        if (error.response && error.response.data) {
            throw error.response.data; // Renvoyer l'erreur sous forme d'objet
        } else {
            throw { system: "Une erreur inattendue est survenue." }; // Erreur générique
        }
    }
};

// Fonction pour vérifier si l'utilisateur est connecté
export const isLoggedIn = async () => {
    try {
        const response = await axiosInstance.get("/auth/verify");
        return response.data;
    } catch (error) {
        throw error;
    }
};

// Fonction pour se déconnecter
export const logout = async () => {
    try {
        const response = await axiosInstance.get("/auth/logout");
        return response.data;
    } catch (error) {
        console.error("Error fetching logout:", error);
    }
};

// Fonction pour créer un compte
export const register = async (data) => {
    try {
        const response = await axiosInstance.post("/auth/register", data);
        return response.data;
    } catch (error) {
        throw error;
    }
};
