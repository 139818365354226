import { lazy } from "react";

import MainLayout from "../layouts/MainLayout";
import { ProtectedRoutes } from "./ProtectedRoutes";

const Tours = lazy(() => import("../views/account/tours/Tours"));
const Profil = lazy(() => import("../views/account/Profil"));
const Settings = lazy(() => import("../views/account/Settings"));
const Machines = lazy(() => import("../views/account/machines/Machines"));
const TourInfo = lazy(() => import("../views/account/tours/TourInfo"));
const MachineInfo = lazy(() => import("../views/account/machines/MachineInfo"));
const Products = lazy(() => import("../views/account/Products"));
const MachineQuota = lazy(() =>
    import("../views/account/machines/MachineQuota")
);

export const UserRoutes = {
    path: "/mon-compte",
    element: <MainLayout />,
    children: [
        {
            element: <ProtectedRoutes />,
            children: [
                // Route pour afficher le profil de l'utilisateur
                {
                    path: "",
                    element: <Profil />,
                },
                // Route pour afficher les informations de l'utilisateur
                {
                    path: "mes-infos",
                    element: <Settings />,
                },
                // Route pour afficher les machines
                {
                    path: "mes-machines",
                    element: <Machines />,
                },
                // Route pour afficher les infos d'une machine
                {
                    path: "mes-machines/:slug",
                    element: <MachineInfo />,
                },
                // Route pour afficher les infos d'une machine selon le jour
                {
                    path: "mes-machines/:slug/:day",
                    element: <MachineQuota />,
                },
                // Route pour afficher les tournées
                {
                    path: "mes-tournees",
                    element: <Tours />,
                },
                // Route pour afficher les infos d'une tournée
                {
                    path: "mes-tournees/:slug",
                    element: <TourInfo />,
                },
                // Route pour afficher les produits
                {
                    path: "mes-produits",
                    element: <Products />,
                },
            ],
        },
    ],
};

// Générateurs de chemins
export const UserPaths = {
    profil: "/mon-compte",
    settings: "/mon-compte/mes-infos",
    machines: "/mon-compte/mes-machines",
    machineInfo: (slug) => `/mon-compte/mes-machines/${slug}`,
    machineQuota: (slug, day) => `/mon-compte/mes-machines/${slug}/${day}`,
    tours: "/mon-compte/mes-tournees",
    tourInfo: (slug) => `/mon-compte/mes-tournees/${slug}`,
    products: "/mon-compte/mes-produits",
};
