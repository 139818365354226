import Routes from "./routes";

export default function App() {

  return (
      <div className="h-full">
          <Routes />
      </div>
  );
}

