import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";

// Store pour la gestion des utilisateurs
const userStore = (set, get) => ({
    user: null,
    timeoutId: null, // ID pour le timeout

    // Fonction pour mettre à jour l'utilisateur
    setUser: (user) => {
        // Définit l'utilisateur
        set(() => ({ user }));
    },

    // Fonction pour effacer l'utilisateur
    clearUser: () => {
        set(() => ({ user: null }));
    },

    // Fonction pour récupérer l'utilisateur
    getUser: () => get().user,
});

// Hook pour utiliser le store
const useUserStore = create(
    persist(userStore, {
        name: "user",
        storage: createJSONStorage(() => localStorage),
    })
);

export default useUserStore;
