import { useRoutes } from "react-router-dom";
import { MainRoutes } from "./MainRoutes";
import { AuthenticationRoutes } from "./AuthenticationRoutes";
import { UserRoutes } from "./UserRoutes";

export default function ThemeRoutes() {
  return useRoutes([
    AuthenticationRoutes,
    UserRoutes,
    MainRoutes,
  ]);
}
