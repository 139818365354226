import React, { Suspense } from "react";
import { Outlet } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";

export default function MainLayout() {
    return (
        <div
            className="h-full grid grid-cols-1 grid-rows-3 auto-rows-min gap-4 overflow-hidden"
            style={{
                gridTemplateRows: "0fr 1fr 0fr",
            }}
        >
            <Header />
            <div className="h-full overflow-auto">
                <Suspense
                    fallback={
                        <div className="w-full h-full flex justify-center items-center">
                            <div className="loader" />
                        </div>
                    }
                >
                    <Outlet />
                </Suspense>
            </div>
            <Footer />
        </div>
    );
}
